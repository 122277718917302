import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
import Newsletter from '../components/footer/newsletter'

export default ({ data }) => {
  return (
    <Layout mode="dark">
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug} />
      <Hero
        mode="default"
        title={data.wordpressPage.acf.header_text}
        content={data.wordpressPage.acf.header_content}
        image={ data.wordpressPage.featured_media ?
            data.wordpressPage.featured_media.localFile.childImageSharp.fluid
          :
            null
          }
        button={
          {
            title: typeof data?.wordpressPage?.acf?.header_button?.title !== 'undefined' ? data.wordpressPage.acf.header_button.title : '',
            url: typeof data?.wordpressPage?.acf?.header_button?.url !== 'undefined' ? data.wordpressPage.acf.header_button.url : '',
            target: typeof data.wordpressPage.acf.header_button.target !== 'undefined' ? data.wordpressPage.acf.header_button.target : '',
          }
        }
      />
      <Container>
        <div style={{textAlign: 'center', margin: '50px 0'}}>
          <h3>Questions?</h3>
          <p>
            Contact <a href="mailto:scholarship@mtafoundation.org" target="_blank">scholarship@mtafoundation.org</a> for more information.
          </p>
        </div>
      </Container>

      <Newsletter />
    </Layout>
  )
}

export const pageQuery = graphql`
query ScholarshipQuery {
  wordpressPage(wordpress_id: {eq: 67}) {
    title
    slug
    acf {
      header_content
      header_text
      header_button {
        url
        title
        target
      }
      rules_of_eligibility {
        rules_header
        rule_1
        rule_2
        rule_3
        terms_header
        terms_condition
      }
      intro_green_box
    }
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`
